<template>
	<v-breadcrumbs v-resize="computeBreadcrumbs" :items="items" :divider="directoryDivider" class="pa-0 mx-1" style="width: 100%">
		<template v-slot:item="props">
			<a v-if="props.item.is_home" style="max-width: 100%" @click="selectHome()">
				<v-layout justify-center align-center text-no-wrap>
					<v-icon :size="`${homeIconWidth + 'px'}`" color="primary">home</v-icon>
					<span v-if="items.length == 1" class="ml-1 truncated-title" v-text="props.item.text" />
				</v-layout>
			</a>
			<span v-else-if="props.item.is_menu">
				<a @click="showMenu($event)">
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<span v-on="on" v-text="props.item.text" />
						</template>
						<span v-text="props.item.hiddenPathText" />
					</v-tooltip>
					<v-menu
						v-model="displayDropDownPath"
						:position-x="menuPositionX"
						:position-y="menuPositionY"
						absolute
						offset-y
						shrink
						close-on-click
						close-on-content-click
						pa-0
						ma-0
					>
						<w-list>
							<w-list-tile v-for="item in hiddenPath" :key="item.name" @click="selectFolder(item)">
								<w-list-tile-content>
									<w-list-tile-title v-text="item.name" />
								</w-list-tile-content>
							</w-list-tile>
						</w-list>
					</v-menu>
				</a>
			</span>
			<span v-else-if="props.item.is_folder && !props.item.is_last">
				<a @click="selectFolder(props.item.folder)">{{ props.item.text }}</a>
			</span>
			<span v-else-if="props.item.is_folder && props.item.is_last">{{ props.item.text }}</span>
		</template>
	</v-breadcrumbs>
</template>

<script>
import { mapState } from 'vuex'
import DocumentsManagerModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsManagerModuleGuard'

export default {
	name: 'DocumentsToolbarBreadcrumbs',
	mixins: [DocumentsManagerModuleGuard],
	props: {
		value: {
			type: Object,
			required: false,
			default: null
		}
	},
	data: function () {
		return {
			items: [],
			truncatedStartName: '...',
			directoryDivider: ' / ',
			hiddenCanvas: null,
			homeIconWidth: 22,
			displayDropDownPath: false,
			hiddenPathText: '',
			hiddenPath: [],
			menuPositionX: null,
			menuPositionY: null
		}
	},
	computed: {
		...mapState({
			selectedCompany: state => state.company.selected,
			isAccountant: state => state.auth.isAccountant
		}),
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		selectedFolderPath: function () {
			let result = []
			let currentFolder = this.model
			if (currentFolder) {
				result.unshift(currentFolder)
			}
			while (currentFolder?.parent) {
				result.unshift(currentFolder.parent)
				currentFolder = currentFolder.parent
			}
			return result
		},
		clientCode: function () {
			return this.isAccountant && this.selectedCompany?.client_code ? this.selectedCompany.client_code : null
		}
	},
	watch: {
		model: {
			handler: function () {
				this.computeBreadcrumbs()
			}
		}
	},
	methods: {
		computeBreadcrumbs: function () {
			this.items.clear()
			this.items.push({
				id: -2,
				is_home: true,
				is_menu: false,
				is_folder: false,
				is_last: false,
				text: this.selectedFolderPath[0]?.name
			})
			let availableWidth = this.$el ? this.$el.clientWidth : 0
			let lastAcceptedIndex = 1
			for (let i = 1; i < this.selectedFolderPath.length; i++) {
				lastAcceptedIndex = i
				let textSize = this.getTextWidth(this.getFullPathName(i)) + this.homeIconWidth // + dividerPadding
				if (textSize < availableWidth) {
					break
				}
			}
			let hiddenPathText = ''
			this.hiddenPath.clear()
			if (lastAcceptedIndex > 1) {
				for (let j = 1; j < lastAcceptedIndex; j++) {
					this.hiddenPath.push(this.selectedFolderPath[j])
					if (hiddenPathText.length > 0) {
						hiddenPathText += this.directoryDivider
					}
					hiddenPathText += this.selectedFolderPath[j].name
				}
				this.items.push({
					id: 0,
					is_home: false,
					is_menu: true,
					is_folder: false,
					is_last: false,
					text: this.truncatedStartName,
					hiddenPathText: hiddenPathText
				})
			}
			for (let k = lastAcceptedIndex; k < this.selectedFolderPath.length; k++) {
				this.items.push({
					id: this.selectedFolderPath[k].id,
					is_home: false,
					is_menu: false,
					is_folder: true,
					is_last: k + 1 == this.selectedFolderPath.length,
					text: this.selectedFolderPath[k].name,
					folder: this.selectedFolderPath[k]
				})
			}
		},
		showMenu: function (event) {
			if (this.hiddenPath && this.hiddenPath.length > 0) {
				this.menuPositionX = event.x
				this.menuPositionY = event.y
				this.displayDropDownPath = true
			}
		},
		selectHome: function () {
			this.selectFolder(this.selectedFolderPath[0])
		},
		selectFolder: function (folder) {
			if (folder) {
				this.model = folder
			}
		},
		getTextWidth: function (text) {
			let result = Number.MAX_VALUE
			if (!this.hiddenCanvas) {
				this.hiddenCanvas = document.createElement('canvas')
			}
			if (this.$el) {
				let context = this.hiddenCanvas.getContext('2d')
				if (this.$el.currentStyle) {
					context.font = this.$el.currentStyle['font']
				} else if (window.getComputedStyle) {
					context.font = window.getComputedStyle(this.$el, null).getPropertyValue('font')
				} else if (document.defaultView && document.defaultView.getComputedStyle) {
					context.font = document.defaultView.getComputedStyle(this.$el, null).getPropertyValue('font')
				} else {
					context.font = this.$el.style['font']
				}
				let metrics = context.measureText(text)
				result = metrics.width
			}
			return result
		},
		getFullPathName: function (offset = 1) {
			let result = ''
			for (let j = this.selectedFolderPath.length - 1; j >= offset; j--) {
				if (result.length > 0) {
					result = this.selectedFolderPath[j].name + this.directoryDivider + result
				} else {
					result = this.selectedFolderPath[j].name
				}
			}
			if (offset > 0) {
				result = this.truncatedStartName + this.directoryDivider + result
			} else {
				result = this.selectedFolderPath[0].name + this.directoryDivider + result
			}
			return result
		}
	}
}
</script>
<style>
li.v-breadcrumbs__divider {
	padding: 0 2px 0 2px !important;
}
</style>
